import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
// import { querystring } from "../libs/Utilitites";

export default function UnauthenticatedRoute({ children, ...rest }) {
    const { isAuthenticated, prevLocation } = useAppContext();
    return (
      <Route {...rest}>
        {!isAuthenticated ? (
          children
        ) : (
          <Redirect to={prevLocation === "" || prevLocation === null ? "/" : prevLocation} />
        )}
      </Route>
    );
}
