import env from "react-dotenv";

export default {
  "domain": env.COGNITO_DOMAIN,
  "scope": [
    "email",
    "profile",
    "openid",
    "aws.cognito.signin.user.admin"
  ],
  "redirectSignIn": env.REDIRECT_URL,
  "redirectSignOut": env.REDIRECT_URL,
  "responseType": "token"
};
